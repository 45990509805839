.listing a {
    color: inherit !important;
    text-decoration: inherit !important;
}

.div-flex-left {
    justify-content: left;
}

.listing p {
    margin-bottom: 0;
}

.listing {
    height: 100%;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.listing .info {
    position: unset;
}

.listing img {
    width: 100%;
    object-fit: cover;
    height: 18rem;
    border-radius: 0.5rem;
    filter: drop-shadow(0 0 0.5rem #bbbbbb);
}

.listing .title {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    text-align: left;
    margin-top: 0.8rem;
}

.listing svg {
    margin-right: 0.3rem;
    height: 0.8rem;
    color: gray;
}

.listing p {
    margin-right: 1rem;
    font-family: 'Raleway', sans-serif;
    color: gray;
}

@media all and (min-width:0px) and (max-width: 480px) {

    .listing .info {
        position: unset;
    }
}

@media (min-width: 1024px) {
    .listing .info {
        position: absolute;
        bottom: 0;
    }
}


/* filter: drop-shadow(5px 5px 0.5rem #cccccc); */