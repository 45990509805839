.configuration {
    position: relative;
}

.input-session {
    /* background-color: white; */
    padding: 1rem;
    margin-top: 1em;
    border-radius: 0.5rem;
}

.dzu-dropzone button {
    background: transparent;
}

thead {
    background: transparent !important;
}