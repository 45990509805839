#root {
    background-color: white !important;
}

.logo {
    height: 8rem;
    margin-left: -0.5rem;
}

.blackOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    --tw-bg-opacity: 0.4;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.whiteOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    /* --tw-bg-opacity: 0.4;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)); */
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.7) 0%,
            rgba(255, 255, 255, 0) 80%);
}

.lightbg {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
    fill: currentColor;
}

.curve {
    top: auto;
    left: 0px;
    bottom: -1px;
    right: 0px;
    width: 100%;
    position: absolute;
    pointer-events: none;
    height: 5rem;
    overflow: hidden !important;
    transform: translateZ(0px);
}

.curve svg {
    position: absolute;
    bottom: 0;
}

.reverse-curve {
    bottom: auto;
    top: 1px;
    left: 0px;
    right: 0px;
    width: 100%;
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    margin-top: -5rem;
    height: 5rem;
}

.cover {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95vh;
}

.cover .bg {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.cover .bg img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.cover .titlediv {
    text-align: left !important;

}

.cover button {
    background: #124d54;
    font-family: 'Raleway', sans-serif;
}

.cover button a {
    color: white;
}

.subcover {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
}

.subcover .bg {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.subcover .bg img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.subcover .title {
    color: #e1d9cf;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1;
    z-index: 100;
    font-family: 'Raleway', sans-serif;
}

.cover .title {
    color: #e1d9cf;
    font-weight: 600;
    font-size: 4rem;
    line-height: 1;
    font-family: 'Raleway', sans-serif;
    text-align: left;
}

.cover .subtitle {
    margin-top: 1rem;
    font-size: 1.125rem;
    line-height: 2rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-size: 2rem;
    font-family: 'Raleway', sans-serif;
}

.grayArea {
    padding-bottom: 5rem;
    --tw-bg-opacity: 1;
    padding-top: 4rem;
    padding-bottom: 8rem;
    background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.container {
    margin-right: auto !important;
    margin-left: auto !important;
    z-index: 100;
}

.bubble {
    color: #ededed;
    padding: 0.5rem;
    text-align: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    /* background-color: #124d54; */
    background: linear-gradient(to bottom right, #e1d9cf 0%, #124D7C 70%);
    ;
    margin: auto;
}

.bubble svg {
    width: 50%;
    height: 100%;

}

.card {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    background: #ededed !important;
}

.card .title {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
}

.card ul {
    margin-left: 1rem;
}

.card .description {
    margin-top: 0.5rem !important;
    color: black;
    font-size: 1.05rem;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.description {
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
    font-size: 1.2rem;
    font-family: 'Raleway', sans-serif;
}

.testimony {
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
}

.description-dark {
    margin-top: 0.5rem !important;
    --tw-text-opacity: 1;
    font-size: 1.2rem;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
    font-family: 'Raleway', sans-serif;
}

.subtitle {
    font-size: 1.2rem;
    /* margin-top: 1rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-size: 2rem; */
    font-family: 'Raleway', sans-serif;
}

.session {
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.padx {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.padxx {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.pady {
    padding-top: 1rem !important;
    padding-bottom: 3rem !important;
}

.padyy {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.padyyy {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.title {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.text {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgba(71, 85, 105, var(--tw-text-opacity));
    font-family: 'Raleway', sans-serif;
}

.text-fat {
    font-weight: 400;
    line-height: 1.625;
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
    font-size: 1.2rem;
}

.round-corner {
    vertical-align: middle !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.div-flex {
    display: flex;
    align-items: top;
    flex-wrap: wrap;
}

.align-baseline {
    align-items: baseline;
}

.grayArea .div-flex {
    align-items: flex-start;
}

.div-flex .icon {
    margin-right: 0.5rem;
}

.title-icon {
    font-size: 1.7rem;
}

.darkBlue {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}

.platforms {
    background-color: white;
}

.listings {
    background-color: white;
}

.listings button {
    background: #124d54;
    font-family: 'Raleway', sans-serif;
}

.employees {
    margin-bottom: 5rem;
}

.employee {
    padding-top: 1.5rem;
    text-align: center;
}

.employee .name {
    font-family: 'Raleway', sans-serif;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
}

.employee .role {
    font-family: 'Raleway', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
    text-transform: uppercase;
    font-weight: 600;

}

.transparency {
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.listing {
    width: 100%;
}

/* .listing img {
    width: 100%;
    object-fit: cover;
    height: 18rem;
    border-radius: 0.8rem;
}

.listing .title {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    text-align: left;
    margin-top: 0.8rem;
}

.listing svg {
    margin-right: 0.5rem;
}

.listing p {
    margin-right: 1.5rem;
    font-family: 'Raleway', sans-serif;
} */

@media all and (min-width:0px) and (max-width: 480px) {

    .logo {
        height: auto;
    }

    .title {
        margin-top: 2rem;
    }

    .bubble {
        margin-top: 1rem;
    }

    /* .sm12\/12 {
        width: 100%;
    } */

    .card .description {
        margin-top: 0.1rem !important;
    }

    .card .title {
        margin-top: 0.5rem;
    }

    .cover .title {
        font-size: 2.5rem;
    }

    .card {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .platforms {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .transparency {
        padding-top: 3rem;
    }

    .subcover .title {
        font-size: 2.2rem;
    }

    .service {
        margin-top: 2rem;
    }
}

@media (min-width: 481px) {

    .transparency {
        padding-top: 3rem;
    }
}

@media (min-width: 768px) {
    /* .md4\/12 {
        width: 33.333333%;
    }

    .md2\/12 {
        width: 16.666666%;
    }

    .md3\/12 {
        width: 25%;
    }

    .md6\/12 {
        width: 50%;
    } */

    .transparency {
        padding-top: 4rem;
    }
}

@media (min-width: 1024px) {
    /* .lg6\/12 {
        width: 50%;
    } */

    .grayArea .container {
        max-width: 1000px;
    }

    .transparency {
        padding-top: 5rem;
    }
}

.justify-center {
    -webkit-justify-content: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.relative {
    position: relative;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.padTop {
    padding-top: 2rem;
}

.padBottom {
    padding-bottom: 2rem;
}