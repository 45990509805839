.card {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    background: transparent !important;
    border: none !important;
}

.bubble {
    color: #ededed;
    padding: 0.5rem;
    text-align: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background: #124d54;
    margin: auto;
}

.bubble svg {
    width: 50%;
    height: 100%;

}

.card .description {
    margin-top: 0.5rem !important;
    color: black;
    font-size: 1.05rem;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}

.tag {
    margin-right: 0.5rem;
    background: #124d54;
    color: white;
    font-weight: 300;
    border-radius: 0.375rem;
    padding: 0.35em 0.65em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    display: inline-block;
    font-size: 0.85em;
    margin-bottom: 0.5rem;
}

.div-flex-center {
    justify-content: center;
}