.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #f0f0f0;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Add shadow */
}

.action-button {
    position: fixed;
    top: 2.5vh;
    right: 3vh;
    z-index: 100;
}

.portal-title {
    position: fixed;
    top: 3vh;
    z-index: 100;
}
