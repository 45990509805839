.dropdown {
    text-align: left;
    margin: 0;
    min-width: 10em;
    margin: 1em;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: 0;
    height: 100%;
    position: relative;
}

.dropdown button {
    min-width: 10em;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.dropdown-header {
    font-size: 1rem !important;
}