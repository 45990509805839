.logo {
    max-height: 5vh;
    object-fit: scale-down;
}

.navbar-expand-lg {
    z-index: 1000;
}

.sandwich-btn {
    color: white;
}

.nav-menu {
    background-color: white;
}

.navbar-item {
    color: #e1d9cf;
    margin-left: 1rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.navbar-item:hover {
    color: #124d54;
}

.navbar-item-menu {
    color: black;
    margin-top: 1rem;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

@media (min-width: 0px) and (max-width: 480px) {
    .logo {
        margin: none;
    }
}