@media all and (min-width:0px) and (max-width: 480px) {
    .modal-dialog {
        max-width: inherit !important;
    }
}

@media (min-width: 481px) {

    .modal-dialog {
        max-width: 90vw !important;
    }

    .listing {
        max-width: 25vw;
        margin: auto;
    }
}