 .dzu-dropzone {
     height: 100%;
     position: relative;
 }


 .dzu-dropzone label {
     position: absolute;
     top: 0;
     left: 0;
     color: black;
 }

 .dzu-dropzone button {
     width: 100%;
     height: 100%;
     background: white;
     border: white;
 }

 .dzu-dropzone input {
     position: absolute;
     bottom: 0;
 }

 .dzu-dropzone button {
     --bs-btn-color: #fff;
     --bs-btn-bg: #fff;
     --bs-btn-border-color: #fff;
     --bs-btn-hover-color: #fff;
     --bs-btn-hover-bg: #fff;
     --bs-btn-hover-border-color: #fff;
     --bs-btn-focus-shadow-rgb: 60, 153, 110;
     --bs-btn-active-color: #fff;
     /* --bs-btn-active-bg: #146c43; */
     --bs-btn-active-border-color: #fff;
     --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
     --bs-btn-disabled-color: #fff;
     --bs-btn-disabled-bg: #fff;
     --bs-btn-disabled-border-color: #fff;
     --bs-btn-active-bg: #fff;
     padding: 0;
 }