@media (min-width: 768px) {
    .md2\/12 {
        width: 16.666666%;
    }

    .md3\/12 {
        width: 25%;
    }

    .md4\/12 {
        width: 33.333333%;
    }

    .md5\/12 {
        width: 41.666667%;
    }

    .md6\/12 {
        width: 50%;
    }

    .md8\/12 {
        width: 66.666667%;
    }

    .md12\/12 {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .lg2\/12 {
        width: 16.666666%;
    }

    .lg3\/12 {
        width: 25%;
    }

    .lg4\/12 {
        width: 33.333333%;
    }

    .lg5\/12 {
        width: 41.666667%;
    }

    .lg6\/12 {
        width: 50%;
    }

    .lg8\/12 {
        width: 66.666667%;
    }

    .lg12\/12 {
        width: 100%;
    }
}

@media all and (min-width:0px) and (max-width: 480px) {
    .sm2\/12 {
        width: 16.666666%;
    }

    .sm3\/12 {
        width: 25%;
    }

    .sm4\/12 {
        width: 33.333333%;
    }

    .sm5\/12 {
        width: 41.666667%;
    }

    .sm6\/12 {
        width: 50%;
    }

    .sm8\/12 {
        width: 66.666667%;
    }

    .sm12\/12 {
        width: 100%;
    }
}

@media (min-width: 1280px) {
    .xl2\/12 {
        width: 16.666666%;
    }

    .xl3\/12 {
        width: 25%;
    }

    .xl4\/12 {
        width: 33.333333%;
    }

    .xl5\/12 {
        width: 41.666667%;
    }

    .xl6\/12 {
        width: 50%;
    }

    .xl8\/12 {
        width: 66.666667%;
    }

    .xl12\/12 {
        width: 100%;
    }
}