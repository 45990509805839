.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background-color: #4dc247;
    color: white;
    border-radius: 50%;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    z-index: 1000;
}

.whatsapp-icon {
    width: 20px;
    margin-right: 10px;
}

.whatsapp-text {
    font-size: 16px;
}