footer .div-flex {
    justify-content: space-between;
    align-items: center;
}

footer .logo {
    margin: auto;
}

footer .copyright {
    margin: auto;
    margin-top: 1rem;
}

footer {
    margin-top: -1px;
}