.item-text {
    text-decoration-line: none;
}

.item-text:hover {
    color: #124d54b8;
}

.item-logo {
    margin-right: 0.5rem;
}

.sidebar .logo {
    margin: auto;
}

.menu {
    position: fixed;
    display: block;
}